import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import ProjectsListing from '../components/ProjectsListing'
import { HPage } from '../components/Headings'

const RelatedProjects = props => {
  if (!props.edges) {
    return null
  }

  return (
    <React.Fragment>
      {props.subhead ? (
        <div>
          <Link to="/our-work" title="Our Work">
            <HPage mt={0} mb={3} is="h2">
              {props.subhead}
            </HPage>
          </Link>
        </div>
      ) : null}
      <ProjectsListing edges={props.edges} limit={props.limit} inline />
    </React.Fragment>
  )
}

RelatedProjects.defaultProps = {
  // TODO This title should be editable, maybe from Options
  subhead: 'Explore More',
  limit: 4,
}

RelatedProjects.propTypes = {
  subtitle: PropTypes.string,
  limit: PropTypes.number,
}

export default RelatedProjects
