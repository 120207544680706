import React from 'react'
import PropTypes from 'prop-types'
import NukaCarousel from 'nuka-carousel'
import isDev from '../utils/is-dev'

const CarouselBannerRightWrapper = props => {
  return <div {...props} />
}

class CarouselBanner extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      slideIndex: 0,
      // TODO Decide whether or not to do height calc
      slideContainerHeight: 1000,
    }

    this.slideContainer = null

    this.carouselImages = this.getCarouselImages()
  }

  getCarouselImages() {
    let carouselImages = []
    const leftItems = this.props.leftItems

    leftItems.forEach((item, index) => {
      let keyStr = `CarouselImage_${index}`
      let backgroundImage = undefined
      let square = null

      if (
        item &&
        item.localFile &&
        item.localFile.childImageSharp &&
        item.localFile.childImageSharp
      ) {
        if (item.localFile.childImageSharp.id) {
          keyStr = `${keyStr}_${item.localFile.childImageSharp.id}`
        }
        if (
          item.localFile.childImageSharp.fluid &&
          item.localFile.childImageSharp.fluid.src
        ) {
          backgroundImage = `url(${item.localFile.childImageSharp.fluid.src})`

          if (item.localFile.childImageSharp.square) {
            square = (
              <img
                role="presentation"
                alt=""
                src={item.localFile.childImageSharp.square.src}
                className="col-12 block m0 xs-show sm-show md-hide lg-hide"
              />
            )
          }
        }
      }

      carouselImages.push(
        <div
          key={keyStr}
          className={`bg-cover bg-center bg-black col-12 h2 white ${
            square ? '' : 'height-50vh'
          } md-height-75vh lg-height-100vh`}
          style={{
            // height: `${this.state.slideContainerHeight}px`,
            // height: this.props.defaultHeight,
            backgroundImage: backgroundImage,
          }}
          role="presentation">
          {square}
        </div>
      )
    })

    return carouselImages
  }

  getSlideContainerHeight() {
    if (this.slideContainer) {
      let computedStyle = window.getComputedStyle(this.slideContainer)
      let slideContainerHeight = computedStyle.getPropertyValue('height')

      this.setState({
        slideContainerHeight: parseFloat(slideContainerHeight, 10),
      })
    }
  }

  handleResize() {
    if (isDev()) {
      console.log('resize')
    }

    if (
      this.getSlideContainerHeight &&
      typeof this.getSlideContainerHeight === 'function'
    ) {
      this.getSlideContainerHeight()
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)

    this.queueNextItem()
    this.getSlideContainerHeight()
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)

    if (window) {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  queueNextItem() {
    const props = this.props
    const leftItems = props.leftItems

    if (!leftItems) {
      return null
    }

    this.timeout = window.setTimeout(() => {
      let newObjectIndex = this.state.slideIndex + 1

      if (newObjectIndex >= leftItems.length) {
        newObjectIndex = 0
      }

      this.setState({
        slideIndex: newObjectIndex,
      })

      this.queueNextItem()
    }, props.autoplayDuration)
  }

  render() {
    const props = this.props
    const state = this.state
    const rightItems = props.rightItems
    const leftItems = props.leftItems

    if (!rightItems && !leftItems) {
      return null
    }

    let nukaCarouselImages = this.carouselImages || this.getCarouselImages()

    let flatSlideIndex = -1

    let rightChildren = rightItems.map((parent, parentIndex) => {
      // Don’t modify the original object, return this instead
      let parentToRender = Object.assign(
        {
          title: null,
          children: [],
        },
        parent
      )

      if (parent.children) {
        parentToRender.children = parent.children.map((childItem, index) => {
          flatSlideIndex = flatSlideIndex + 1
          // This is necessary
          let currentFlatIndex = flatSlideIndex
          let active = currentFlatIndex === this.state.slideIndex

          if (
            !props.rightRenderItemChildren ||
            typeof props.rightRenderItemChildren !== 'function'
          ) {
            return childItem.title
          }

          return props.rightRenderItemChildren(childItem, {
            active: active,
            index: index,
            itemProps: {
              onMouseOver: () => {
                if (clearTimeout && this.timeout) {
                  clearTimeout(this.timeout)
                }

                if (!active) {
                  this.setState({
                    slideIndex: currentFlatIndex,
                  })
                }
              },
            },
          })
        })
      } else {
        // TODO Remove majority of this conditional, and do small
        // based on if parent.children instead?

        flatSlideIndex = flatSlideIndex + 1
        // This is necessary
        let currentFlatIndex = flatSlideIndex
        let active = currentFlatIndex === this.state.slideIndex

        return props.rightRenderItem(parentToRender, {
          active: active,
          index: parentIndex,
          itemProps: {
            onMouseOver: () => {
              if (clearTimeout && this.timeout) {
                clearTimeout(this.timeout)
              }

              if (!active) {
                this.setState({
                  slideIndex: currentFlatIndex,
                })
              }
            },
          },
        })
      }

      if (
        !props.rightRenderItem ||
        typeof props.rightRenderItem !== 'function'
      ) {
        return parentToRender.title
      }

      return props.rightRenderItem(parentToRender, {})
    })

    let RightWrapper = CarouselBannerRightWrapper

    if (props.rightContainerComponent) {
      RightWrapper = props.rightContainerComponent
    }

    return (
      <div
        className={`md-flex overflow-hidden md-max-height-75vh lg-max-height-100vh ${props.parentClassName}`}>
        <div
          className="md-max-height-75vh lg-max-height-100vh self-stretch col-12 md-col-6 bg-black white"
          ref={el => (this.slideContainer = el)}>
          <NukaCarousel
            renderTopLeftControls={null}
            renderTopCenterControls={null}
            renderTopRightControls={null}
            renderCenterLeftControls={null}
            renderCenterCenterControls={null}
            renderCenterRightControls={null}
            renderBottomLeftControls={null}
            renderBottomCenterControls={null}
            renderBottomRightControls={null}
            heightMode="max"
            speed={600}
            easing="easePolyInOut"
            initialSlideHeight={state.slideContainerHeight}
            vertical={true}
            autoplay={false}
            slideIndex={state.slideIndex}
            afterSlide={slideIndex => {
              // https://github.com/FormidableLabs/nuka-carousel/issues/416#issuecomment-423589720
              // Kept at 4.2.2 in the meantime
              if (!Number.isNaN(slideIndex)) {
                this.setState({ slideIndex: slideIndex })
              }
            }}>
            {nukaCarouselImages}
          </NukaCarousel>
        </div>
        <div
          className={`${props.rightParentClassName} ${props.rightParentClassNameAdditional}`}>
          <RightWrapper className={props.rightClassName}>
            {rightChildren}
          </RightWrapper>
        </div>
      </div>
    )
  }
}

CarouselBanner.defaultProps = {
  parentClassName: 'bg-silver',
  rightParentClassName:
    'min-height-100vw md-height-100 self-start items-center col-12 md-col-6',
  rightParentClassNameAdditional: '',
  autoplayDuration: 4000,
}

CarouselBanner.propTypes = {
  items: PropTypes.array,
  itemsLeft: PropTypes.array,
  itemsRight: PropTypes.array,
  rightRenderItem: PropTypes.func,
  rightRenderItemChildren: PropTypes.func,
  rightParentClassName: PropTypes.string,
  rightParentClassNameAdditional: PropTypes.string,
  rightContainerComponent: PropTypes.func,
  autoplayDuration: PropTypes.number.isRequired,
}

export default CarouselBanner
