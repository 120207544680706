import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import FitText from '@kennethormandy/react-fittext'

// Ours
import urlAdjustments from '../utils/url-adjustments'
import getCtaIdFromSelect from '../utils/get-ctaid-from-select'
import RelatedProjects from '../components/RelatedProjects'
import CallToAction from '../components/CallToAction'
import MainBlocksMap from '../components/MainBlocksMap'
import { BlockWrapper } from '../components/MainBlocks'
import CarouselBanner from '../components/CarouselBanner'

const RightContainerComponent = props => {
  const { children, ...remainingProps } = props

  return (
    <div className="p2 sm-p3 lg-p4 lg-mt3 max-width-3 mx-auto">
      <div className="font-weight-100 line-height-2 mb1">
        <FitText
          {...remainingProps}
          compressor={1.05}
          minFontSize={24}
          maxFontSize={120}>
          <div>
            <div>{props.title}</div>
            <hr className="m0 border-white col-4 md-col-2 my2 sm-my3 md-my2 lg-my3 muted" />
            {props.children}
          </div>
        </FitText>
      </div>
    </div>
  )
}

RightContainerComponent.defaultProps = {
  title: 'Hello, we’re Cygnus',
}

RightContainerComponent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const IndexPage = props => {
  const data = props.data
  const banner = data.wpPage.frontPageBanner

  const blocks = data.wpPage.main.mainContent
  let chosenCta = getCtaIdFromSelect(data.wpPage.details)

  return (
    <div>
      <CarouselBanner
        parentClassName="bg-magenta white"
        leftItems={data.wpMenu.menuItems.nodes.map((item, index) => {
          if (
            item &&
            item.connectedNode &&
            item.connectedNode.node &&
            (item.connectedNode.node.featuredImage ||
              item.connectedNode.node.featuredImagePortrait)
          ) {
            if (
              item.connectedNode.node.featuredImagePortrait &&
              item.connectedNode.node.featuredImagePortrait
                .featuredMediaPortrait
            ) {
              return item.connectedNode.node.featuredImagePortrait
                .featuredMediaPortrait
            } else {
              return item.connectedNode.node.featuredImage.node
            }
          }

          return {}
        })}
        rightParentClassNameAdditional="order-last bg-magenta white"
        rightItems={data.wpMenu.menuItems.nodes}
        rightContainerComponent={props => (
          <RightContainerComponent
            {...props}
            title={banner.title || data.wp.generalSettings.title}>
            <div>
              {banner.prefix}
              {props.children}
              {banner.suffix}
            </div>
          </RightContainerComponent>
        )}
        rightRenderItem={(item, settings) => {
          let linkSep = <span>, </span>

          if (settings.index >= data.wpMenu.menuItems.nodes.length - 1) {
            linkSep = <span />
          } else if (settings.index >= data.wpMenu.menuItems.nodes.length - 2) {
            linkSep = <span>, and </span>
          }

          return (
            <span key={item.id} className="inline">
              <Link
                className={`inline white ${settings.active ? 'muted' : ''}`}
                to={urlAdjustments(item.url)}
                {...settings.itemProps}>
                {item.label.replace('and ', '')}
              </Link>
              {linkSep}
            </span>
          )
        }}
      />
      <MainBlocksMap blocks={blocks} />
      <BlockWrapper bg="silver" maxWidth={6}>
        <RelatedProjects
          edges={data.allWpProject.edges}
          limit={4}
          inline
          subhead="Learn more about our work"
        />
      </BlockWrapper>
      <CallToAction ctaId={chosenCta} />
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    wp {
      generalSettings {
        title
      }
    }

    # Query for landing page menu
    # “landing” matches the slug set in functions.php
    wpMenu(slug: { eq: "landing" }) {
      databaseId
      menuItems {
        nodes {
          databaseId
          id
          order
          label
          url

          ... on WpMenuItem {
            title
            connectedNode {
              node {
                id
                ... on WpExpertise {
                  title
                  featuredImage {
                    node {
                      altText
                      databaseId
                      localFile {
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                          square: fluid(
                            maxWidth: 1280
                            maxHeight: 1280
                            cropFocus: CENTER
                          ) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                  featuredImagePortrait {
                    featuredMediaPortrait {
                      altText
                      databaseId
                      localFile {
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                          square: fluid(
                            maxWidth: 1280
                            maxHeight: 1280
                            cropFocus: CENTER
                          ) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }

                ... on WpPage {
                  title
                  featuredImage {
                    node {
                      altText
                      databaseId
                      localFile {
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                          square: fluid(
                            maxWidth: 1280
                            maxHeight: 1280
                            cropFocus: CENTER
                          ) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                  featuredImagePortrait {
                    featuredMediaPortrait {
                      altText
                      databaseId
                      localFile {
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                          square: fluid(
                            maxWidth: 1280
                            maxHeight: 1280
                            cropFocus: CENTER
                          ) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }

                ... on WpProject {
                  title
                  featuredImage {
                    node {
                      altText
                      databaseId
                      localFile {
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                          square: fluid(
                            maxWidth: 1280
                            maxHeight: 1280
                            cropFocus: CENTER
                          ) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                  featuredImagePortrait {
                    featuredMediaPortrait {
                      altText
                      databaseId
                      localFile {
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                          square: fluid(
                            maxWidth: 1280
                            maxHeight: 1280
                            cropFocus: CENTER
                          ) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    allWpProject(
      limit: 4
      filter: { projectDetails: { projectStatus: { eq: "completed" } } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          uri
          details {
            description
          }
          projectDetails {
            location
            projectStatus
            projectRelatedexpertise {
              ... on WpExpertise {
                parentId
                title
                slug
              }
            }
            projectRelatedexpertiseOther {
              ... on WpProject_Projectdetails_projectRelatedexpertiseOther {
                postTitle
              }
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  id
                  resize(width: 1280, height: 1280, cropFocus: CENTER) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }

    wpPage(isFrontPage: { eq: true }) {
      title
      slug
      modified

      # This doesn’t come through via isFrontPage only
      # https://github.com/wp-graphql/wp-graphql-acf/issues/1#issuecomment-673748370
      frontPageBanner {
        title
        prefix
        suffix
      }
      details {
        description
        ctaSelect
      }
      main {
        subtitle
        ... on WpPage_Main {
          mainContent {
            ... on WpPage_Main_MainContent_SectionHeading {
              fieldGroupName
              text
            }
            ... on WpPage_Main_MainContent_Text {
              fieldGroupName
              sectionHeading
              text
            }
            ... on WpPage_Main_MainContent_Image {
              fieldGroupName
              sectionHeading
              image {
                uri
                altText
                localFile {
                  childImageSharp {
                    id
                    fluid(maxWidth: 2560) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              captionDetails {
                detail
                fieldGroupName
                label
                labelCustom
              }
              captionText
              settings {
                showFullWidth
              }
            }
            ... on WpPage_Main_MainContent_TwoColumn {
              fieldGroupName
              image {
                altText
                localFile {
                  childImageSharp {
                    id
                    fluid(maxWidth: 1280) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              content {
                subhead
                text
                callToAction {
                  target
                  title
                  url
                }
              }
              settings {
                bg
                color
                sticky
              }
            }
            ... on WpPage_Main_MainContent_ThreeColumn {
              fieldGroupName
              sectionHeading
              columns {
                bigNumber
                image {
                  altText
                  localFile {
                    childImageSharp {
                      id
                      resize(width: 1280, height: 1280, cropFocus: CENTER) {
                        src
                      }
                    }
                  }
                }
                subhead
                text
              }
              settings {
                showBigNumbers
                showImages
                showImagesStylized
              }
            }
            ... on WpPage_Main_MainContent_PartnerLogos {
              fieldGroupName
              sectionHeading
              partnerLogos {
                altText
                id
                localFile {
                  childImageSharp {
                    id
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            ... on WpPage_Main_MainContent_Testimonials {
              fieldGroupName
              testimonials {
                author
                organization
                quote
              }
            }
            ... on WpPage_Main_MainContent_Gallery {
              captionText
              settings {
                showFullWidth
              }
              fieldGroupName
              sectionHeading
              gallery {
                altText
                localFile {
                  extension
                  childImageSharp {
                    id
                    fluid(maxWidth: 2560) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            ... on WpPage_Main_MainContent_Project {
              fieldGroupName
              project {
                ... on WpProject {
                  title
                  uri
                  projectDetails {
                    projectStatus
                  }
                  details {
                    description
                  }
                  featuredImage {
                    node {
                      id
                      localFile {
                        extension
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                  featuredImagePortrait {
                    featuredMediaPortrait {
                      localFile {
                        extension
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
