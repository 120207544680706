import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import fecha from 'fecha'

// Ours
import unesc from '../utils/unescape'
import urlAdjustments from '../utils/url-adjustments'
import ImgSharp from './ImgSharp'
import { WordmarkGroup } from './Logo'
import { H4 } from './Headings'
import { Carousel } from './Carousel'
import ProjectsListingNewsDescription from './ProjectsListingNewsDescription'

const handleFeatureImage = function (node, fallbackDimensions) {
  fallbackDimensions = fallbackDimensions || { width: 1280, height: 800 }

  if (node.featuredImage && node.featuredImage.node) {
    return <ImgSharp {...node.featuredImage.node} className="col-12 block m0" />
  }

  if (
    node.projectMainContent &&
    node.projectMainContent.gallery &&
    node.projectMainContent.gallery.length >= 1
  ) {
    return <Carousel gallery={node.projectMainContent.gallery} />
  }

  // Fallback
  return (
    <svg
      viewBox={`0 0 ${fallbackDimensions.width} ${fallbackDimensions.height}`}
      className="mb0 block col-12 bg-black">
      <g
        transform={`translate(340, ${
          fallbackDimensions.height / 2.5
        }) scale(3)`}>
        <WordmarkGroup color="white" />
      </g>
    </svg>
  )
}

const ConditionalLink = props => {
  if (props.to && typeof props.to === 'string') {
    if (props.to.includes('http')) {
      let { to, ...remainingProps } = props
      return (
        <a
          href={to}
          {...remainingProps}
          target="_blank"
          rel="noopener noreferrer">
          {remainingProps.children}
        </a>
      )
    }
    return <Link {...props} />
  }

  return <div {...props} />
}

ConditionalLink.propTypes = {
  to: PropTypes.string.isRequired,
}

const ProjectsListing = props => {
  let projectEdges = props.edges

  let hoverClasses = 'hover-bg-magenta hover-white hover-transition'

  if (props.layout === 'news') {
    hoverClasses = 'ProjectsListingItem-link--news'
  }

  return (
    <ul className="ProjectsListing list-style-none m0 p0 sm-flex flex-wrap items-stretch">
      {projectEdges.map(({ node }, index) => {
        if (!props.limit || props.limit - 1 >= index) {
          // Remove placeholder project
          // TODO Make this generic
          if (node.slug.includes('placeholder')) {
            return false
          }

          let displayDate = node.date || ''

          if (displayDate) {
            displayDate = new Date(displayDate)
            if (displayDate) {
              displayDate = fecha.format(displayDate)
            }
          }

          let to = urlAdjustments(node.uri)
          let buttonText
          let useHoverClasses = true

          // News link blog
          if (node.postFormats && node.postFormats.nodes.length >= 1) {
            if (
              node.postFormats.nodes[0].name === 'Video' ||
              node.postFormats.nodes[0].name === 'post-format-video'
            ) {
              if (node.postFormatVideo && node.postFormatVideo.link) {
                to = node.postFormatVideo.link.url
                buttonText = node.postFormatVideo.link.title || 'Watch'
              }
            }

            if (
              node.postFormats.nodes[0].name === 'Link' ||
              node.postFormats.nodes[0].name === 'post-format-link'
            ) {
              if (node.postFormatLink && node.postFormatLink.link) {
                to = node.postFormatLink.link.url
                buttonText =
                  node.postFormatLink.link.title || 'Continue Reading'
              }
            }
          }

          if (
            node.projectDetails &&
            node.projectDetails.projectStatus === 'in-progress'
          ) {
            to = `${props.slugPrefix}#${node.slug}`
            useHoverClasses = false
          }

          let featuredImage = handleFeatureImage(
            node,
            props.layout !== 'news'
              ? { width: 1280, height: 1280 }
              : { width: 1280, height: 800 }
          )
          let title = unesc(node.title)

          return (
            <li
              key={`ProjectItem_${index}`}
              className={`ProjectsListing-item mb-line flex col-12 sm-col-6 md-col-4 ${
                props.inline && index + 1 === props.limit
                  ? 'xs-hide md-hide lg-hide'
                  : ''
              }`}>
              <ConditionalLink
                id={node.slug}
                className={`ProjectsListingItem-link block col-12 bg-white black ${
                  useHoverClasses ? hoverClasses : ''
                }`}
                to={to}
                title={title}>
                {featuredImage}
                {props.layout && props.layout === 'news' ? (
                  <ProjectsListingNewsDescription
                    {...node}
                    title={title}
                    date={displayDate}
                    buttonText={buttonText}
                  />
                ) : (
                  <div className="p2 pb3">
                    <H4 is="h3" lineHeight={3} mb={0} mt={0}>
                      {title}
                    </H4>
                    <p className="my0">
                      {node.details.description || node.projectDetails.location}
                    </p>
                  </div>
                )}
              </ConditionalLink>
            </li>
          )
        }

        return null
      })}
    </ul>
  )
}

ProjectsListing.defaultProps = {
  edges: [],
  slugPrefix: '/our-work',
  limit: 0,
  inline: false,
  layout: 'work',
}

ProjectsListing.propTypes = {
  edges: PropTypes.array.isRequired,
  slugPrefix: PropTypes.string,
  limit: PropTypes.number,
  inline: PropTypes.bool,
  layout: PropTypes.string,
}

export default ProjectsListing
