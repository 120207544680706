import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from './Button'

const ProjectsListingNewsDescription = props => {
  let label = ''
  let color = ''

  let categorySlugs =
    props.categories && props.categories.nodes
      ? props.categories.nodes.map(cat => (cat.slug ? cat.slug : ''))
      : []

  // TODO This is pretty hard-coded for the categories we have,
  // could be more generic
  if (categorySlugs.indexOf('insights') !== -1) {
    label = 'Insights'
    color = 'yellow'
  } else if (categorySlugs.indexOf('news') !== -1) {
    label = 'News'
    color = 'blue'
  } else {
    label = 'In-Progress'
    color = 'magenta'
  }

  // let categories = (
  //   <ul>
  //     {props.categories && props.categories.length >= 1
  //       ? props.categories.map((cat, index) => {
  //           return <li>{cat.name}</li>
  //         })
  //       : null}
  //   </ul>
  // )

  return (
    <div className={`border-top border-thick border-${color} p2 pb3 relative`}>
      <strong className={`h5 line-height-3 block bold ${color}`}>
        {label}
      </strong>
      <h3 className="h5 line-height-3 mt0">{props.title}</h3>
      <div className="sm-h6">
        {props.details ? (
          <Fragment>
            {props.details.description ? (
              <p>{props.details.description}</p>
            ) : null}
          </Fragment>
        ) : null}
        {props.projectDetails ? (
          <Fragment>
            {props.projectDetails.client ? (
              <div>
                <strong>Client</strong> {props.projectDetails.client}
              </div>
            ) : null}
            {props.projectDetails.client ? (
              <div>
                <strong>Location</strong> {props.projectDetails.location} (
                {props.projectDetails.year ? props.projectDetails.year : null})
              </div>
            ) : null}
          </Fragment>
        ) : null}
        {label !== 'In-Progress' ? (
          <Button as="div" color={color}>
            {props.buttonText}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

ProjectsListingNewsDescription.defaultProps = {
  buttonText: 'Continue Reading',
}

ProjectsListingNewsDescription.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.object),
  }),
  buttonText: PropTypes.string,
}

export default ProjectsListingNewsDescription
